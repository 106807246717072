import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import Jumbotron from "../components/pohrby/Jumbotron";
import Poplatky from "../components/pohrby/Poplatky";
import Img from "gatsby-image";


import LinkButton from "../components/pohrby/LinkButton";
import CoUdelame from "../components/pohrby/CoProVasUdelame";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import SupportPanel from "../components/SupportPanel";
import PoSvem from "../components/pohrby/PoSvem";
import UvodniText from "../components/pohrby/UvodniText";
import Features from "../components/pohrby/Features";
import Naceneni from "../components/pohrby/Naceneni";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import Press from "../components/pohrby/Press";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Postup from "../components/pohrby/Postup";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import NaceneniV2 from "../components/brandingV2/NaceneniV2";
import RegionsV2 from "../components/brandingV2/RegionsV2";


const faqData = [
  {
    otazka: "Mohu si napsat vlastní smuteční řeč?",
    odpoved: 'Ano můžete. Ale doporučujeme smuteční řeč ještě před obřadem probrat s řečníkem nebo mu můžete dát vlastní poznámky a konkrétní informace, které chcete, aby zmínil. Jestli si nejste jistí, zda smuteční řeč nechat na řečníkovi nebo ne, s rozhodováním vám pomůže tento <a href="https://goodbye.cz/poradna/smutecni-rec-rady-tipy">článek</a>.'
  },
  {
    otazka: "Musí smuteční řeč přednést řečník?",
    odpoved: 'Záleží na vás. Je možné i nechat smuteční řeč pronést jedním z pozůstalých.'
  },
  {
    otazka: "Kde se bude obřad konat a kolik lidí může přijít?",
    odpoved: "Obřady se konají v Praze, Brně, Plzni, Liberci, Ústí nad Labem, Pardubicích, Kladně, Jihlavě, Uherském Hradišti nebo Jablonci nad Nisou. Pro naše pražské zákazníky v Úřední obřadní síni v Praze v Olšanech. Pro brněnské pak v Brněnském krematoriu. K sezení mají tyto síně 40 míst a ke stání 60. Dohromady se tedy může zúčastnit až 100 pozůstalých. Pro více informací nás prosím kontaktujte."
  },
  {
    otazka: "Jak dlouho bude obřad trvat?",
    odpoved: "Na běžný smuteční obřad je stanovena doba 30 minut, z toho počátečních 5 minut je určeno na přípravu vystavené rakve a květinových darů. Následujících 20 minut je samotný pietní akt. Posledních 5 minut je věnováno poděkování a kondolenci pozůstalých."
  },
  {
    otazka: "Mohu si vybrat vlastní hudbu?",
    odpoved: "Hudbu je možné vybrat buď z předpřipraveného repertoáru nebo po domluvě přinést na flash disku vlastní skladby."
  },
  {
    otazka: "Je kremace zahrnuta v ceně?",
    odpoved: "Ano, zpopelnění i uložení do dočasné urny je zahrnuto v ceně."
  },
  {
    otazka: "Co všechno cena obsahuje?",
    odpoved: `Cena obsahuje vyzvednutí zesnulého kdekoliv v daném městě, úpravu těla a uložení do rakve. Tvorbu parte, přichystání obřadní síně a <a href="/poradna/smutecni-kytice-pohreb/">květinové výzdoby</a>. <a href="/poradna/smutecni-rec-rady-tipy/">Řečníka</a>, který vás provede obřadem a přednese smuteční řeč. Cena zahrnuje také zpopelnění, uložení do dočasné urny a převoz urny na pobočku ve vašem okolí. V případě Péče+ vám urnu osobně doručíme až domů kamkoliv po regionu, vyřídíme <a href="/slovnik-pojmu/umrtni-list/">úmrtní list</a> na matrice, a další služby.`
  },
  {
    otazka: "Kdy vás můžu kontaktovat?",
    odpoved: "Kdykoliv. Naše zákaznická linka je vám k dispozici 24 hodin 7 dní v týdnu ve dne i v noci na čísle 315 558 136. Pokud se rozhodne pro kremaci bez obřadu, kontaktujete nás prosím co nejdříve. Je možné, že tak předejdete zbytečným poplatkům od nemocnic a jiných institucí."
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů nebo do zdravotní instituce, která nedisponuje vlastním chladícím zařízením, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze, Ostravě a Libereckém kraji 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Jak budu informován v průběhu procesu?",
    odpoved: `O každé změně (vyzvednutí zesnulého, provedení kremace, předpokládané datum doručení <a href="/poradna/kremacni-popel/">urny</a>...) vás budeme informovat telefonicky, případně e-mailem nebo SMS zprávou. Forma komunikace bude jen na vás a na vašich preferencích. Zároveň se můžete kdykoliv obrátit na naši zákaznickou linku.`
  },
  {
    otazka: "Jak se ke mně dostane popel zesnulého?",
    odpoved: "Popel si budete moct sami vyzvednout na pobočce ve vašem okolí, nebo při využití Péče+ vám urnu doručíme osobně až domů. Na termín vyzvednutí vás včas upozorníme."
  },
  {
    otazka: "Kdo může urnu s popelem převzít?",
    odpoved: "Urnu si budete moc převzít pouze vy. Na termín vyzvednutí vás včas upozorníme. Pak se stačí jen řídit otevírací dobou dané pobočky, kde si urnu můžete vyzvednout. Nebo při využití Péče+ vám urnu doručíme osobně až domů."
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do rakve vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže je záměna vyloučena.`
  },
];

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ObradInner = styled.div`
  background: #FBFBFA;
  padding-bottom: 1px;


`;


const Obrad = ({ data }) => {
  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        title="Důstojný pohřeb s obřadem od 18 900 Kč | Goodbye.cz"
        customTitle
        description="Pomůžeme vám zařídit osobní rozloučení v obřadní síni. Hudba, květiny, promítání fotografií. Jedna transparentní cena. Vše sjednáte online."
        image={"/obradOg.png"}
      />

      <StructuredHodnoceni />

      <ObradInner>
        
        

        <Jumbotron
          h1="Pohřeb se smutečním obřadem"
          p="Řekněte svému blízkému sbohem při tradičním pohřbu ve smuteční síni. Celý obřad vám pomůžeme přizpůsobit podle vašich přestav."
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={<>Zaměstnanci Goodbye byli <span>maximálně ochotní, všechno nám vysvětlili a pomohli nám zvládnout situaci,</span> která byla od začátku pro celou rodinu velkým neštěstím. Děkujeme.</>}
          recenzeImgFixed
          recenzeImg={data.recenzeSample.childImageSharp.fixed}
          recenzeImgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Jana Č."}
          zakaznikKdo={"Zákaznice"}
          isDark
          breadscrum={
            <Breadscrum
              visible
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Pohřeb s obřadem", url: "/pohreb-s-obradem/"}
              ]}
            />
          }
        />


        <UvodniText
          h2="Ztratit někoho blízkého je těžké. My to chápeme."
          p={<>Jsme tu od toho, abychom vás provedli všemi potřebnými kroky. Pomůžeme vám naplánovat jedinečné poslední rozloučení s vaším blízkým. Vytvoříme parte, vyřídíme všechny dokumenty na matrice, pomůžeme vám se smuteční řečí a zorganizujeme i samotný pohřeb. Vše spolu vyřešíme přes internet a z bezpečí vašeho domova.<br/><br/>Nově nabízíme také organizaci <Link to="/pravoslavny-pohreb/">pravoslavných pohřbů</Link> v souladu s tradicemi a zvyklostmi.</>}
          img={data.pohrebUvod.childImageSharp.fixed}
          imgSmall={data.pohrebUvodSmall.childImageSharp.fixed}
        />



        <Features isPruh />



        <NaceneniV2
          h2="V ceně je zahrnuto kompletně vše, co budete potřebovat"
          p="To jak se rozloučíte, je jen na vás. My se postaráme o vše ostatní za předem stanovenou cenu."
          checklist={["Kompletní sjednání po internetu", "Přeprava vašeho blízkého k nám", "Tvorba parte", "30 minutový smuteční obřad", "Zajištění řečníka, hudby, fotek a květinové výzdoby", "Základní rakev", "Samotná kremace včetně poplatků"]}
          price="22 990 Kč"
          extrasText="Nabízíme i možnost připlatit si za jiné doplňky"
          extras={[
            {
              h4: "Výběr jiné rakve",
              p: "Nabízíme i několik dalších dřevěných variant.",
              price: "2 900 - 3 900 Kč",
              img: data.jinaRakev.childImageSharp.fixed,
            },
            {
              h4: "Výběr jiných květin",
              p: "Mimo základní výzdoby můžeme objednat květiny živé.",
              price: "1 990 - 4 500 Kč",
              img: data.jineKytky.childImageSharp.fixed,
            },
            {
              h4: "Výběr jiné urny",
              p: "Vybírat můžete z dřevěných, keramických a dalších uren.",
              price: "790 - 3 900 Kč",
              img: data.jinaUrna.childImageSharp.fixed,
            },
          ]}
        />


        <Postup
          isPruh

          fotka1={data.postup1.childImageSharp.fluid}
          heading1={"Nezávazně si zavoláme a probereme vaši situaci"}
          text1={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Pokud nám zavoláte, jsme připraveni vaši situaci okamžitě řešit. Nebo se můžete jednoduše zeptat na cokoliv, co vás napadne. Na telefonu jsme pro vás každý den, ve dne v noci."}

          fotka2={data.postup2.childImageSharp.fluid}
          heading2={"Pohřeb s námi naplánujete z bezpečí domova"}
          text2={<>Na plánování budete mít dostatek času. Nemusíte nikam chodit. Vše s vámi vyřešíme po telefonu a on-line (výběr <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, <Link to="/rakve/">rakve</Link>, parte a podobně). Zvládnete to tak bez zbytečného tlaku a stresu. Zbytek můžete nechat na nás. Celou dobu je vám naše pohřební služba k dipozici a připravena pomoci.</>}

          fotka3={data.postup3.childImageSharp.fluid}
          heading3={"O vše ostatní se už postaráme my"}
          text3={"Ať už si vyberete jakýkoliv pohřeb, my jsme tu pro vás, abychom vyřešili formality na matrice, oblečení zesnulého i jeho úpravu, stav smuteční síně a další povinnosti. Postaráme se, aby poslední rozloučení proběhlo tak, jak jste to plánovali."}
        />
        
        <RecenzePohreb />

        <RegionsV2 />

        <Press />

        <SeVsimPomuzeme />

        <FAQ data={faqData}/>


      </ObradInner>
    </Layout>
  );
}

export const query = graphql`
  query {


    big: file(relativePath: { eq: "obradPozadi.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "obradPozadiSmall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice4.png" }) {
      childImageSharp {
        fixed(width: 340) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvod: file(relativePath: { eq: "pohrebUvod.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvodSmall: file(relativePath: { eq: "pohrebUvod.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    postup1: file(relativePath: { eq: "postup1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup2: file(relativePath: { eq: "postup2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup3: file(relativePath: { eq: "postup3.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice4Small.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }








    jinaRakev: file(relativePath: { eq: "jinaRakev.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jineKytky: file(relativePath: { eq: "jineKytky.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jinaUrna: file(relativePath: { eq: "jinaUrna.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekKvetiny: file(relativePath: { eq: "doplnekKvetiny.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekRakve: file(relativePath: { eq: "doplnekRakve.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekUrny: file(relativePath: { eq: "doplnekUrny.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    naceneni: file(relativePath: { eq: "naceneniPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  }
`;

export default Obrad;


